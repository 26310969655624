<template>
  <div class="cardCotizadorResItem">
    <div v-if="first_item && item_selected == item_index">
      <div class="cardCotizadorRes_first_item">
        <div class="list_1_item">
          {{
            item[item.length - 1]
              ? t(`app.cardCotizadorResumen.${item[0]}`)
              : item[0]
          }}
        </div>
        <div class="cotizadorResumenData1TitleEdit" @click="call_action">
          <editar_eliminar
            type="editar"
            formato="icon"
            ancho_img="0.8"
            font_size_text="10"
          />
        </div>
      </div>
      <div class="list_1_valor">{{ data_formated }}</div>
    </div>
    <div v-else>
      <div class="list_1_item">
        {{
          item[item.length - 1]
            ? t(`app.cardCotizadorResumen.${item[0]}`)
            : item[0]
        }}
      </div>
      <div class="list_1_valor">{{ data_formated }}</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { mapActions, mapState } from "vuex";
import editar_eliminar from "../generales/editar_eliminar.vue";

export default {
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  props: {
    item: Array,
    first_item: Boolean,
    tipo_action: String,
    item_selected: Number,
    item_index: Number,
  },
  components: {
    editar_eliminar,
  },
  computed: {
    ...mapState(["transaccion", "i18n_locales"]),

    data_formated() {
      let aux = "";
      if (this.item[0] == "recibe") {
        if (this.transaccion.moneda_destino_id == "2") {
          let aux2 = this.item[1].split(" ");
          console.log(aux2);
          aux2[0] = parseFloat(aux2[0]);
          console.log(aux2[0]);
          aux = aux2[0].toLocaleString(
            `${this.locale}${this.i18n_locales[this.locale].locales}`
          );
          aux = `${aux} ${aux2[1]}`;
        } else {
          let aux2 = this.item[1].split(" ");
          aux2[0] = parseInt(aux2[0]);
          aux = aux2[0].toLocaleString(
            `${this.locale}${this.i18n_locales[this.locale].locales}`
          );
          aux = `${aux} ${aux2[1]}`;
        }
      } else {
        aux = this.item[1];
      }
      return aux;
    },
  },
  methods: {
    ...mapActions([]),

    call_action() {
      this.$emit("call_action_", this.tipo_action);
    },
  },
  mounted() {},
};
</script>

<style>
.cardCotizadorResItem {
  font-family: "Roboto";
  font-style: normal;
  letter-spacing: 0.5px;
}
.cardCotizadorResItemDestacado {
  font-weight: 700;
}

.cardCotizadorRes_first_item {
  display: flex;
  justify-content: space-between;
}
.cotizadorResumenData1TitleEdit {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.2rem;
  text-align: right;
  letter-spacing: 0.5px;
  text-decoration-line: underline;
  color: #614ad3;
}
</style>