<template>
  <screen_width_cont>
    <div class="myBodyGeneral">
      <div
        class="myHeader"
        ref="myHeader_cotizadorResumen"
        id="myHeader_cotizadorResumen"
      >
        <div
          class="cont_300_basico ancho_acotado"
          @click.prevent="focusGralCotiDestiOn"
        >
          <div class="header_top">
            <headerCerrarInfoCuenta
              @callCuenta="callCuentaLocal"
              @callInfo="callInfoLocal"
              @cancelar="cancelarLocal"
            />
          </div>

          <div class="cotizadorResumenTitle title">
            <!-- title_margin_top -->
            {{ t("app.cotizadorResumen.tit") }}
          </div>
        </div>
      </div>

      <blurComp
        class="blur_sup"
        :style="blur_sup_cotizadorResumen"
        :scroll_to_="scroll_to_top"
        tipo="sup"
      />

      <div
        class="contenido centrado"
        :style="contenido_cotizadorResumen"
        v-show="cont_visible"
        @scroll="onScroll"
        ref="contenido_cotizadorResumen"
      >
        <div class="cont_l_r_1_rem ancho_acotado">
          <div
            class="cotizadorResumenData1"
            :class="item_selected != 1 ? 'cotizadorResumenData1_chico' : ''"
          >
            <div
              v-if="resumen1Items.length > 0"
              class="cotizadorResumenData1Title_first"
            >
              <div
                class="cotizadorResumenItemTitle cotizadorResumenItemTitle_point"
                @click="change_item_selected(1)"
              >
                <div>{{ t("app.cotizadorResumen.sub_tit_1") }}</div>
                <div>
                  <img :src="ojo" alt="" v-if="item_selected != 1" /><img
                    :src="ojoCerrado"
                    alt=""
                    v-else
                  />
                </div>
              </div>
            </div>
            <div
              v-for="(item, i) in resumen1Items"
              :key="i"
              class="cotizadorResumenData1Item cont_l_r_1_rem"
            >
              <cardCotizadorResumen
                :item="item"
                :first_item="i == 0 ? true : false"
                @call_action_="call_action"
                tipo_action="volverCotizador"
                :item_selected="item_selected"
                :item_index="1"
              />
            </div>
          </div>

          <div class="cotizadorResumenHr">
            <div></div>
          </div>

          <div
            class="cotizadorResumenData1"
            :class="item_selected != 2 ? 'cotizadorResumenData1_chico' : ''"
          >
            <div
              v-if="resumen2Items.length > 0"
              class="cotizadorResumenData1Title"
            >
              <div
                class="cotizadorResumenItemTitle cotizadorResumenItemTitle_point"
                @click="change_item_selected(2)"
              >
                <div>{{ t("app.cotizadorResumen.sub_tit_2") }}</div>
                <div>
                  <img :src="ojo" alt="" v-if="item_selected != 2" /><img
                    :src="ojoCerrado"
                    alt=""
                    v-else
                  />
                </div>
              </div>
            </div>
            <div
              v-for="(item, i) in resumen2Items"
              :key="i"
              class="cotizadorResumenData1Item cont_l_r_1_rem"
            >
              <cardCotizadorResumen
                :item="item"
                :first_item="i == 0 ? true : false"
                @call_action_="call_action"
                tipo_action="volverDestinatario"
                :item_selected="item_selected"
                :item_index="2"
              />
            </div>
          </div>

          <div class="cotizadorResumenHr">
            <div></div>
          </div>

          <div class="cotizadorResumenMensaje">
            <div class="cotizadorResumenItemTitle">
              <div>{{ t("app.cotizadorResumen.sub_tit_3") }}</div>
              <!-- <div><img :src="ojo" alt="" v-if="item_selected == 3"><img :src="ojoCerrado" alt="" v-else></div> -->
            </div>

            <div class="cotizadorResumenMensajeLargo cont_l_r_1_rem">
              ({{ t("app.cotizadorResumen.caracteres") }})
            </div>
          </div>

          <div class="cotizadorResumenMensajeTexto cont_l_r_1_rem">
            <textBoxMensaje
              @set_mensaje="set_mensaje"
              :placeholder="t('app.cotizadorResumen.mensaje')"
            />
          </div>

          <!-- <div class="cotizadorResumenBtn">
            <btnAction :textBtn="t('app.comunes.btns.confirmar')" @action="continuar" colorBack="azul" colorText="blco"
              :estadoBtn="estadoBtn" />
          </div> -->
        </div>
      </div>

      <blurComp
        class="blur_inf"
        :style="blur_inf_cotizadorResumen"
        :scroll_to_="scroll_to_bottom"
        tipo="inf"
      />

      <div class="footer_estrecho_centrado" ref="footer_cotizadorResumen">
        <div class="footer_estrecho">
          <div class="cotizadorResumenBtn">
            <btnAction
              :textBtn="t('app.comunes.btns.confirmar')"
              @action="continuar"
              colorBack="azul"
              colorText="blco"
              :estadoBtn="estadoBtn"
            />
          </div>
          <div class="cotizadorResumenEtapas">
            <pasosGiro paso="4" totales="6" />
          </div>
          <div class="cotizadorResumenVolverCont">
            <volver @volver_emit="volver" />
          </div>

          <div class="footer_env_seguro">
            <enviosSeguros @callEnvioSeguroModal_="callEnvioSeguroModal" />
          </div>
        </div>
      </div>
    </div>
  </screen_width_cont>
</template>  

<script>
import { modalController, menuController } from "@ionic/vue";
import { useI18n } from "vue-i18n";
import headerCerrarInfoCuenta from "../components/generales/headerCerrarInfoCuenta.vue";
import btnAction from "../components/generales/btnAction.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import envioSeguroHome from "./modales/enviosSegurosHome.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import cardCotizadorResumen from "../components/simulador/cardCotizadorResumen.vue";
import textBoxMensaje from "../components/simulador/textBoxMensaje.vue";
import enviosSeguros from "../components/generales/envios_seguros.vue";
import volver from "../components/generales/volver.vue";
import blurComp from "../components/generales/blur.vue";
import {
  blur_sup_scroll,
  blur_inf_scroll,
  contenido_style,
  blur_sup_style,
  blur_inf_style,
} from "../assets/funciones/blur";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  name: "cotizadorResumen",
  components: {
    headerCerrarInfoCuenta,
    btnAction,
    pasosGiro,
    cardCotizadorResumen,
    textBoxMensaje,
    enviosSeguros,
    volver,
    blurComp,
    screen_width_cont,
  },
  data() {
    return {
      mensaje: "",
      header: 0,
      footer: 0,
      cont_visible: false,
      scroll_to_bottom: true,
      scroll_to_top: true,
      contenido_cotizadorResumen: {},
      blur_sup_cotizadorResumen: {},
      blur_inf_cotizadorResumen: {},
      item_selected: 0,
      ojo: require("../assets/imgs/ojo.svg"),
      ojoCerrado: require("../assets/imgs/ojoCerrado.svg"),
    };
  },
  computed: {
    ...mapState([
      "configuracion",
      "transaccion",
      "dataResumen",
      "heigthScreen",
      "widthScreen",
      "plataforma",
      "nav_stack",
      "menuSpDesde",
      "desde",
      "respInscribeGiro",
      "monto_inicio",
      "pais_destino_inicial",
    ]),

    resumen1Items() {
      let aux = [];
      if (this.dataResumen && this.dataResumen.length > 0) {
        if (this.item_selected != 1) {
          for (let index = 0; index < this.dataResumen[0].length; index++) {
            const element = this.dataResumen[0][index];
            if (element[0] == "envias" || element[0] == "recibe") {
              aux.push(element);
            }
          }
        } else {
          aux = this.dataResumen[0];
        }
      }
      // console.log(aux);
      return aux;
    },
    resumen2Items() {
      // console.log(this.dataResumen);
      let aux = [];
      if (this.dataResumen && this.dataResumen.length > 0) {
        if (this.item_selected != 2) {
          for (let index = 0; index < this.dataResumen[1].length; index++) {
            const element = this.dataResumen[1][index];
            if (
              this.transaccion.forma_entrega_id == "1" &&
              (element[0] == "nombre" || element[0] == "nom_bco")
            ) {
              aux.push(element);
            } else if (
              this.transaccion.forma_entrega_id == "2" &&
              (element[0] == "nombre" || element[0] == "entrega_caja")
            ) {
              aux.push(element);
            } else if (
              this.transaccion.forma_entrega_id == "6" &&
              (element[0] == "nombre" || element[0] == "deposito_wallet")
            ) {
              aux.push(element);
            }
          }
        } else {
          aux = this.dataResumen[1];
        }
      }
      // console.log(aux);
      return aux;
    },

    estadoBtn() {
      let aux = "active";
      return aux;
    },
  },
  methods: {
    ...mapMutations([
      "updateBloquearNavegacion",
      "updateMenuSpDesde",
      "updateTransaccion",
      "setshowSpinner",
      "set_dataResumen",
      "set_nav_stack",
      "reset_transaccion",
      "set_pre_values_cotizador",
    ]),
    ...mapActions(["formatCotizadorResumen", "confirmTransaction"]),

    change_item_selected(item) {
      if (this.item_selected == item) {
        this.item_selected = 0;
      } else {
        this.item_selected = item;
      }
    },
    callCuentaLocal() {
      this.updateMenuSpDesde({ desde: "cotizadorResumen", menuSp: true });
      menuController.open("firstMenu");
    },
    callInfoLocal() {
      this.updateMenuSpDesde({ desde: "cotizadorResumen", menuSp: true });

      this.set_nav_stack({
        desde: ["cotizadorResumen"],
        menuSp: this.nav_stack.menuSp,
      });

      this.$router.push({
        name: "centroAyuda",
        params: { tema: "0" },
        replace: true,
      });
    },
    cancelarLocal() {
      // this.updateTransaccion({
      //   moneda_destino_sigla: "",
      //   banco: "",
      //   id_banco_destino: "",
      //   tipo_cuenta: "",
      //   cuenta_corriente: "",
      //   motivo_transaccion: "",
      //   numero_cuenta: "",
      //   id_persona: "",
      //   forma_entrega_id: "",
      //   tasa_mostrar: "",
      //   detalle_entrega_id: "",
      // });
      this.reset_transaccion();
      this.set_pre_values_cotizador({
        monto_inicio: this.monto_inicio,
        pais_destino_inicial: this.pais_destino_inicial,
      });
      if (this.menuSpDesde.desde == "cotizadorDestinatario") {
        this.$router.push({ name: "home", replace: true });
      } else if (this.menuSpDesde.desde == "ingresar_giro") {
        this.$router.push({ name: "destinatarios", replace: true });
      } else if (this.menuSpDesde.desde == "repetir_giro") {
        this.$router.push({ name: "home", replace: true });
      }
    },
    volver() {
      this.$router.push({ name: "cotizadorMotivoRemesa", replace: true });
    },
    async callEnvioSeguroModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: envioSeguroHome,
      });
      modal.onDidDismiss().then(() => {
        this.updateBloquearNavegacion(false);
      });
      return modal.present();
    },
    call_action(tipo) {
      console.log(this.menuSpDesde);
      if (tipo == "volverCotizador") {
        if (this.menuSpDesde.desde == "ingresar_giro") {
          this.$router.push({ name: "ingresar_giro", replace: true });
        } else if (this.menuSpDesde.desde == "repetir_giro") {
          this.$router.push({ name: "repetir_giro", replace: true });
        } else {
          this.$router.push({ name: "cotizador", replace: true });
        }
      } else if (tipo == "volverDestinatario") {
        if (this.menuSpDesde.desde == "ingresar_giro") {
          this.$router.push({ name: "ingresar_giro", replace: true });
        } else if (this.menuSpDesde.desde == "repetir_giro") {
          this.$router.push({ name: "repetir_giro", replace: true });
        } else {
          this.$router.push({ name: "cotizadorDestinatario", replace: true });
        }
      }
    },
    set_mensaje(msg) {
      this.mensaje = msg;
    },
    async continuar() {
      this.updateTransaccion({
        mensaje: this.mensaje,
      });
      this.confirmTransaction();
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      this.scroll_to_top = blur_sup_scroll(scrollTop);
      this.scroll_to_bottom = blur_inf_scroll(
        scrollTop,
        clientHeight,
        scrollHeight
      );
    },
  },
  watch: {
    heigthScreen() {
      this.contenido_cotizadorResumen = contenido_style(
        this.heigthScreen,
        this.header,
        this.footer,
        this.plataforma == "web" ? 63 : 0
      );
    },
    respInscribeGiro() {
      if (this.respInscribeGiro.status && this.cont_visible) {
        this.setshowSpinner(false);
        if (this.respInscribeGiro.resp) {
          this.$router.push({ name: "cotizadorEtapas", replace: true });
        } else {
          // this.$router.push({ name: "cotizadorEtapas", replace: true });
          console.log("---------------ERROR", this.respInscribeGiro.msg);
        }
      }
    },
  },
  ionViewWillEnter() {
    // console.log(this.transaccion.monto_pagar, this.transaccion.monto_origen, this.transaccion.pais_destino);
    console.log(this.transaccion);

    this.formatCotizadorResumen();
  },
  ionViewDidEnter() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.header = this.$refs["myHeader_cotizadorResumen"].offsetHeight;
        this.footer = this.$refs["footer_cotizadorResumen"].offsetHeight;
        this.cont_visible = true;
        this.contenido_cotizadorResumen = contenido_style(
          this.heigthScreen,
          this.header,
          this.footer,
          this.plataforma == "web" ? 63 : 0
        );
        this.blur_sup_cotizadorResumen = blur_sup_style(this.header);
        this.blur_inf_cotizadorResumen = blur_inf_style(this.footer);
      }, 100);

      setTimeout(() => {
        if (
          this.$refs["contenido_cotizadorResumen"].scrollHeight >
          this.$refs["contenido_cotizadorResumen"].clientHeight
        ) {
          this.scroll_to_bottom = false;
        }
      }, 100);
    });
  },
  ionViewDidLeave() {
    this.set_dataResumen({});
  },
};
</script>

<style>
.cotizadorResumenTitle {
  text-align: center;
  color: #801b1c;
}

/* .cotizadorResumenData1 {
} */
.cotizadorResumenData1_chico {
  height: 10rem;
  overflow-y: hidden;
}

.cotizadorResumenData1Title_first {
  margin-top: 0.5rem;
}

.cotizadorResumenData1Title {
  margin-top: 0.5rem;
}
.cotizadorResumenItemTitle_point {
  cursor: pointer;
}

.cotizadorResumenItemTitle {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #614ad3;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cotizadorResumenItemTitle img {
  width: 1.5rem;
}

.cotizadorResumenData1Item {
  margin-top: 1.0625rem;
}

.cotizadorResumenHr {
  padding: 0.5rem 0;
}

.cotizadorResumenHr div {
  border-bottom: 1px solid #787878;
}

.cotizadorResumenMensaje {
  margin-top: 0.5rem;
}

.cotizadorResumenMensajeLargo {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 1rem;
  text-align: left;
  letter-spacing: 1px;
  color: #787878;
}

.cotizadorResumenMensajeTexto {
  margin-top: 1rem;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #787878;
}

.cotizadorResumenBtn {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  width: 100%;
  margin-top: 1rem;
}

.cotizadorResumenEtapas {
  margin-top: 1rem;
}

.cotizadorResumenVolverCont {
  margin-top: 1rem;
  text-align: center;
}
</style>