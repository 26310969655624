<template>
  <div
    class="inputContainer"
    :class="
      stateInput == 'inicio'
        ? 'inicio'
        : stateInput == 'onFocus'
        ? 'onFocus'
        : stateInput == 'post'
        ? 'post'
        : stateInput == 'onError'
        ? 'onError'
        : ''
    "
  >
    <!-- <input
      class="textareaBox"
      maxlength="50"
      @focus="cambiarType($event)"
      @blur="cambiarType($event)"
    /> -->
    <!-- v-model="msgText"
      name="Text1"
      cols="40" -->
    <textarea
      class="textareaBox"
      rows="2"
      maxlength="50"
      :placeholder="placeholder"
      @focus="cambiarType($event)"
      @blur="cambiarType($event)"
      v-model="valor"
    ></textarea>
  </div>
</template>

<script>
export default {
  props:{
    placeholder: String
  },
  data() {
    return {
      stateInput: "inicio",
      valor:""
    };
  },
  methods: {
    async cambiarType(evt) {
      let evento = evt.type;

      if (evento == "focus") {
        this.stateInput = "onFocus";
        // this.inFocus = true;
      } else if (evento == "blur") {
        // this.inFocus = false;
        if (this.error) {
          this.stateInput = "onError";
        } else {
          this.stateInput = "post";
        }
        this.$emit("set_mensaje", this.valor);
      }

      //   this.interactuado = true;
    },
  },
};
</script>

<style>
.inputContainer {
  width: 100%;
  border-radius: 10px;
  min-height: 2.75rem;
  height: fit-content;
  padding: 1rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #787878;
}
.textareaBox {
  border: none;
  background-color: transparent !important ;
  width: 100%;
}
.textareaBox:focus {
  outline: none;
}
.textareaBox::placeholder {
  color: var(--customG-787878);
  opacity: 1;
}
:-ms-.textareaBox-placeholder {
  color: var(--customG-787878);
}

::-ms-.textareaBox-placeholder {
  color: var(--customG-787878);
}
</style>